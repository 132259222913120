import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Landing from './components/landing/Landing'

const Main = () => {
  return (
    <Routes> {/* The Routes decides which component to show based on the current URL.*/}
        <Route exact path='/' element={ <Landing/> }></Route>
    </Routes>
  );
}

export default Main;