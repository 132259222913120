function IconItemVertical({icon, header, description}) {
    return (
        <div className="icon-item-vertical">
          <img className='icon-item-vertical-icon filter-accent-color' src={icon} />
          <p className='icon-item-vertical-header'>{header}</p>
          <p className='icon-item-vertical-description'>{description}</p>
      </div>
    );
}

export default IconItemVertical;