import React, { useState } from 'react'
import { StatusContext } from '../../context/StatusContext';
import { useTranslation } from 'react-i18next'
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import IconItemHorizontal from '../icon_item_vertical/IconItemVertical';
import Footer from './../footer/Footer';
import logo from './../../res/logos/logo_dark.svg';
import img0 from './../..//res/images/backgrounds/0.jpg';
import img1 from './../..//res/images/backgrounds/1.jpg';
import img2 from './../..//res/images/backgrounds/2.jpg';
import img3 from './../..//res/images/backgrounds/3.jpg';
import img4 from './../..//res/images/backgrounds/4.jpg';
import img5 from './../..//res/images/backgrounds/5.jpg';
import img6 from './../..//res/images/backgrounds/6.jpg';
import img7 from './../..//res/images/backgrounds/7.jpg';
import img8 from './../..//res/images/backgrounds/8.jpg';
import img9 from './../..//res/images/backgrounds/9.jpg';
import img10 from './../..//res/images/backgrounds/10.jpg';
import img11 from './../..//res/images/backgrounds/11.jpg';
import img12 from './../..//res/images/backgrounds/12.jpg';
import img13 from './../..//res/images/backgrounds/13.jpg';
import img14 from './../..//res/images/backgrounds/14.jpg';
import img15 from './../..//res/images/backgrounds/15.jpg';
import img16 from './../..//res/images/backgrounds/16.jpg';
import img17 from './../..//res/images/backgrounds/17.jpg';
import img18 from './../..//res/images/backgrounds/18.jpg';
import img19 from './../..//res/images/backgrounds/19.jpg';
import img20 from './../..//res/images/backgrounds/20.jpg';
import img21 from './../..//res/images/backgrounds/21.jpg';
import icon_code from './../../assets/icons/code.svg'
import icon_devices from './../../assets/icons/devices.svg'
import icon_mobile from './../../assets/icons/mobile.svg'

const Landing = () => {

    const { t } = useTranslation();

    let minImageNumber = 0;
    let maxImageNumber = 21;
    let imageNumber = Math.floor(Math.random() * (maxImageNumber - minImageNumber + 1) + minImageNumber)

    return (
        <>
            <section className={'hero bg' + imageNumber}>
                <div>
                    <img src={logo} className="hero-logo" alt="Hibiscus Technology logo" />
                    <p>{t('hero-motto')}</p>
                    <a href='#about' className='button'>{t('action-read-more')}</a>
                </div>
            </section>
            <section id='about'>
                <h1>{t('intro-title')}</h1>
                <hr></hr>
                <p>{t('intro-content')}</p>
            </section>
            <section className='divider'></section>
            <section id='services'>
                <h1>{t('services-title')}</h1>
                <div className='horizontal-list'>
                    <IconItemHorizontal icon={icon_mobile} header={t('services-mobile-header')} description={t('services-mobile-description')}/>
                    <IconItemHorizontal icon={icon_devices} header={t('services-web-header')} description={t('services-web-description')}/>
                    <IconItemHorizontal icon={icon_code} header={t('services-platform-header')} description={t('services-platform-description')}/>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Landing