import React from 'react';
import { StatusContext } from './context/StatusContext';
import { Helmet } from "react-helmet";
import './i18n';
import { withTranslation } from 'react-i18next';
import Main from './Main'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { withRouter } from "./utils/Router"

class App extends React.Component {

  app;
  analytics;

  constructor(props) {
    super(props);

    this.state = {
      //
    }

    if (!App.app) {
      // Initialize Firebase+Analytics
      App.app = initializeApp({
        apiKey: "AIzaSyCHPORl7J1BTks_NG-DjQHGVFFqYiP8qBw",
        authDomain: "website-hibiscus-technology.firebaseapp.com",
        projectId: "website-hibiscus-technology",
        storageBucket: "website-hibiscus-technology.appspot.com",
        messagingSenderId: "490102903189",
        appId: "1:490102903189:web:2e9020e6b5aa4ff5f5a69c",
        measurementId: "G-LP8YS7V6VT"
      });
      App.analytics = getAnalytics(App.app);
    }
  }

  render() {
    const { t } = this.props;

    return <StatusContext.Provider value={{
      
    }}>
      <Helmet>
        <title>{t('helmet_title')}</title>
      </Helmet>
      <Main/>
    </StatusContext.Provider>
  }
}

export default withRouter(withTranslation()(App))